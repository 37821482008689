<template>
  <div class="HomeL2">
    <div class="body">

      <div class="bottom">
        <div class="bottom" id="HomeL2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      tab: 1,
      dataArr: {
        xdata: ['一月', '二月', '三月', '四月', '五月', '六月'],
        disinfeced: [150, 320, 300, 210, 240, 180],//实际考勤人数, 288
        placeData: [320, 435, 490, 340, 320, 270]//应考勤人数, 360
      }
    }
  },
  mounted() {
    this.GetEcharts()
  },
  methods: {


    GetEcharts() {
      // mock 数据
      // mock 数据
      const dataArr = {
        xdata: ['01-01', '01-02', '01-03', '01-04', '01-05', '01-06', '01-07'],
        disinfeced: [10, 0, 0, 0, 0, 0, 288],
        placeData: [10, 0, 0, 0, 0, 0, 360]
      }

      const legend = {
        data: ['场所数', '已消杀'],
        textStyle: { fontSize: 14, color: '#fff' },
        itemWidth: 25,
        itemHeight: 15,
        itemGap: 15,
        bottom: '5%',
        selectedMode: false
      }
      const grid = { top: '10%', left: '10%', right: '3%', bottom: '15%' }
      // xAxis
      const xAxis = {
        axisTick: { show: true },
        axisLine: { lineStyle: { color: 'rgba(255,255,255, .2)' } },
        axisLabel: { textStyle: { fontSize: 12, color: '#fff' }, },
        data: dataArr.xdata
      }

      // yAxis
      const yAxis = [{
        splitLine: { lineStyle: { color: 'rgba(255,255,255, .05)' } },
        axisLine: { show: false, },
        axisLabel: { textStyle: { fontSize: 16, color: '#fff' } }
      }, {
        // 双y轴，主要用于折线图，所以不需要显示坐标
        show: false
      }]

      // series
      const series = [{
        z: 3,
        name: '上部1',
        type: 'pictorialBar',
        symbolPosition: 'end',
        data: dataArr.placeData,
        symbol: 'diamond',
        symbolOffset: [0, '-50%'],
        symbolSize: [30, 20],
        itemStyle: {
          borderColor: '#2fffa4',
          borderWidth: 2,
          color: '#2fffa4'
        },
      }, {
        z: 1,
        type: 'bar',
        name: '场所数',
        barGap: '-100%',
        barWidth: 30,
        data: dataArr.placeData,
        itemStyle: {
          borderColor: '#2fffa4',
          borderWidth: 1,
          color: {
            type: 'linear',
            x: 0, x2: 1, y: 0, y2: 0,
            colorStops: [{ offset: 0, color: 'rgba(47,255,164,.2)' }, { offset: 0.5, color: 'rgba(47,255,164,.2)' }, { offset: 0.5, color: 'rgba(49,245,160,.7)' }, { offset: 1, color: 'rgba(49,245,160,.2)' }]
          }
        },
      }, {
        z: 2,
        name: '底部',
        type: 'pictorialBar',
        data: [1, 1, 1, 1, 1, 1, 1, 1],
        symbol: 'diamond',
        symbolOffset: [0, '50%'],
        symbolSize: [30, 20],
        itemStyle: {
          color: {
            type: 'linear',
            x: 0, x2: 1, y: 0, y2: 0,
            colorStops: [{ offset: 0, color: '#2fffa4' }, { offset: 0.5, color: '#2fffa4' }, { offset: 0.5, color: 'rgba(49,245,160,.7)' }, { offset: 1, color: '#31f5a0' }]
          }
        },
      }, {
        z: 3,
        name: '上部1',
        type: 'pictorialBar',
        symbolPosition: 'end',
        data: dataArr.disinfeced,
        symbol: 'diamond',
        symbolOffset: [0, '-50%'],
        symbolSize: [30, 20],
        itemStyle: {
          borderColor: '#2fffa4',
          borderWidth: 2,
          color: '#2fffa4'
        },
      }, {
        z: 1,
        stack: '总量',
        type: 'bar',
        name: '已消杀',
        barWidth: 30,
        // barGap: '-100%',
        data: dataArr.disinfeced,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0, x2: 1, y: 0, y2: 0,
            colorStops: [{ offset: 0, color: 'rgba(49,245,160,.7)' }, { offset: 0.5, color: 'rgba(49,245,160,.5)' }, { offset: 0.5, color: 'rgba(49,245,160,.5)' }, { offset: 1, color: 'rgba(49,245,160,.7)' }]
          }
        },
      }, {
        z: 9,
        // 双y轴
        yAxisIndex: 1,
        name: '完成率', type: 'line',
        symbol: 'circle',
        symbolSize: [20, 20],
        color: {
          type: 'linear', x: 1, y: 0, x2: 0, y2: 0,
          // 0% 处的颜色                           // 100% 处的颜色
          colorStops: [{ offset: 0, color: '#ffd11a' }, { offset: 1, color: '#fff5cc' }],
          global: false // 缺省为 false
        },
        lineStyle: {
          color: {
            type: 'linear', x: 1, y: 0, x2: 0, y2: 0,
            // 0% 处的颜色                           // 100% 处的颜色
            colorStops: [{ offset: 0, color: '#ffd11a' }, { offset: 1, color: '#fff5cc' }],
            global: false // 缺省为 false
          }
        },
        // 修改的是线下区域的颜色
        areaStyle: {
          color: new echarts.graphic.LinearGradient(
            // 右/下/左/上
            0, 0, 0, 1, [
            { offset: 0, color: 'rgba(255, 209, 26, .2)' },
            { offset: 1, color: 'transparent' }
          ])
        },
        label: {
          show: true,
          formatter: params => {
            return `${params.value}%`
          },
          textStyle: { fontSize: 16, color: '#ffd11a' }
        },
        data: dataArr.rateData
      }]
      var option = { xAxis, yAxis, series, grid, legend, backgroundColor: 'rgba(0, 0, 0, .8)' }
      // series
      // let option = {
      //   backgroundColor: 'transparent',
      //   legend: {
      //     data: ['应考勤人数', '实际考勤人数'],
      //     textStyle: { fontSize: 14, color: '#CCCCCC' },
      //     itemWidth: 25,
      //     itemHeight: 12,
      //     itemGap: 15,
      //     right: '5%',
      //     selectedMode: false
      //   },
      //   xAxis: {
      //     axisTick: { show: true },
      //     axisLine: { lineStyle: { color: 'rgba(255,255,255, .2)' } },
      //     axisLabel: { textStyle: { fontSize: 12, color: '#999999' }, },
      //     data: this.dataArr.xdata
      //   },
      //   yAxis: [{
      //     splitLine: { lineStyle: { color: 'rgba(255,255,255, .6)', type: 'dashed', show: true } },
      //     axisLine: { show: false, },
      //     axisLabel: { textStyle: { fontSize: 12, color: '#999999' } },
      //   }],
      //   grid: {
      //     top: '16%', left: '8%', right: '3%', bottom: '10%'
      //   },
      //   series: [{
      //     z: 3,
      //     name: '上部1',
      //     type: 'pictorialBar',
      //     symbolPosition: 'end',
      //     data: this.dataArr.placeData,
      //     symbol: 'diamond',
      //     symbolOffset: [0, '-50%'],
      //     symbolSize: [24, 16],
      //     itemStyle: {
      //       borderColor: 'rgba(47,255,164,.2)',
      //       borderWidth: 2,
      //       color: 'rgba(47,255,164,.8)',
      //     },
      //   }, {
      //     z: 1,
      //     type: 'bar',
      //     name: '应考勤人数',
      //     barGap: '-100%',
      //     barWidth: 24,
      //     data: this.dataArr.placeData,
      //     itemStyle: {
      //       color: {
      //         type: 'linear',
      //         x: 0, x2: 1, y: 0, y2: 0,
      //         colorStops: [{ offset: 0, color: 'rgba(47,255,164,.2)' }, { offset: 0.5, color: 'rgba(47,255,164,0.2)' }, { offset: 0.5, color: 'rgba(49,245,160,.5)' }, { offset: 1, color: 'rgba(49,245,160,.2)' }]
      //       }
      //     },
      //   }, {
      //     z: 3,
      //     name: '上部1',
      //     type: 'pictorialBar',
      //     symbolPosition: 'end',
      //     data: this.dataArr.disinfeced,
      //     symbol: 'diamond',
      //     symbolOffset: [0, '-50%'],
      //     symbolSize: [24, 16],
      //     itemStyle: {
      //       borderColor: '#1B6BFF',
      //       borderWidth: 2,
      //       color: 'rgba(27,107,255,0.8)'
      //     },
      //   }, {
      //     z: 1,
      //     stack: '总量',
      //     type: 'bar',
      //     name: '实际考勤人数',
      //     barWidth: 24,
      //     // barGap: '-100%',
      //     data: this.dataArr.disinfeced,
      //     itemStyle: {
      //       color: {
      //         type: 'linear',
      //         x: 0, x2: 1, y: 0, y2: 0,
      //         colorStops: [{ offset: 0, color: 'rgba(27,107,255,0.2)' }, { offset: 0.5, color: 'rgba(27,107,255,.2)' }, { offset: 0.5, color: 'rgba(27,107,255,0.7)' }, { offset: 1, color: 'rgba(27,107,255,.4)' }]
      //       }
      //     },
      //   }]
      // }
      var myChart = echarts.init(document.getElementById('HomeL2'));
      // 绘制图表
      myChart.setOption(option);
    },

  }
}
</script>

<style lang="scss" scoped>
.HomeL2 {
  height: 100%;
  width: 100%;
  padding: 20px 30px 0;
  box-sizing: border-box;

  .body {
    width: 100%;
    height: 100%;



    .bottom {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
