
<script>
import * as echarts from 'echarts';
import { Getprovince } from '@/api/bs'
const china = require('../../utils/china.json');
export default {
  render: function (createElement) {
    return createElement("div", {
      attrs: {
        id: "main",
      },
      style: {
        height: "100%",
        width: '100%',
        margin: 'auto'
      },
    });
  },
  data() {
    return {
      dataList: [
        { name: "南海诸岛" },
        { ename: "beijing", name: "北京省" },
        { ename: "tianjin", name: "天津省" },
        { ename: "shanghai", name: "上海省" },
        { ename: "chongqing", name: "重庆省" },
        { ename: "hebei", name: "河北省" },
        { ename: "henan", name: "河南省" },
        { ename: "yunnan", name: "云南省" },
        { ename: "liaoning", name: "辽宁省" },
        { ename: "heilongjiang", name: "黑龙江省" },
        { ename: "hunan", name: "湖南省" },
        { ename: "anhui", name: "安徽省" },
        { ename: "shandong", name: "山东省" },
        { ename: "xinjiang", name: "新疆省" },
        { ename: "jiangsu", name: "江苏省" },
        { ename: "zhejiang", name: "浙江省" },
        { ename: "jiangxi", name: "江西省" },
        { ename: "hubei", name: "湖北省" },
        { ename: "guangxi", name: "广西省" },
        { ename: "gansu", name: "甘肃省" },
        { ename: "shanxi", name: "山西省" },
        { ename: "neimenggu", name: "内蒙古省" },
        { ename: "shanxi1", name: "陕西省" },
        { ename: "jilin", name: "吉林省" },
        { ename: "fujian", name: "福建省" },
        { ename: "guizhou", name: "贵州省" },
        { ename: "guangdong", name: "广东省" },
        { ename: "qinghai", name: "青海省" },
        { ename: "xizang", name: "西藏省" },
        { ename: "sichuan", name: "四川省" },
        { ename: "ningxia", name: "宁夏省" },
        { ename: "hainan", name: "海南省" },
        { name: "台湾省" },
        { ename: "xianggang", name: "香港省" },
        { ename: "aomen", name: "澳门省" },
      ],
      province:undefined,
      toolTipData:[],
    };
  },
  methods: {
    async loadData() {
        const res = await Getprovince({province:this.province})
        if (res.code === 200 && res.data && res.data.length) {
          this.toolTipData=res.data
            this.initEchart()
        }
    },
    initEchart() {
      var myChart = this.$echarts.init(document.getElementById("main"));
      this.$echarts.registerMap('china', china)
      var mapName = 'china';
      let _this = this
      var data = this.dataList
      var geoCoordMap = {};
      var toolTipData = [
        {
          name: "江苏省",
          value: 2 ,
          areas: ["南京"]
        },
        {
          name: "山东省",
          value: 1,
        },
      ];
      if(this.toolTipData.length){
        toolTipData=this.toolTipData
      }
      /*获取地图数据*/
      myChart.showLoading();
      var mapFeatures = echarts.getMap(mapName).geoJson.features;
      myChart.hideLoading();
      mapFeatures.forEach(function (v) {
        // 地区名称
        var name = v.properties.name+'省';
        // 地区经纬度
        geoCoordMap[name] = v.properties.cp;
      });

      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
            });
          }
        }
        return res;
      };
      var option = {
        // backgroundColor: "#003366",
        title: {
          show: true,
          // text: "项目分布图",
          x: 'center',
          top: "10",
          textStyle: {
            color: "#fff",
            fontFamily: "等线",
            fontSize: 18,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            if (typeof params.value[2] == 'undefined') {
              let toolTiphtml = '';
              for (let i = 0; i < toolTipData.length; i++) {
                if (params.name == toolTipData[i].name) {
                  toolTiphtml += toolTipData[i].name + "：" + toolTipData[i].value;
                }
              }

              return toolTiphtml;
            } else {
              let toolTiphtml = '';
              for (let i = 0; i < toolTipData.length; i++) {
                if (params.name == toolTipData[i].name) {
                  toolTiphtml += toolTipData[i].name + "：" + toolTipData[i].value;
                }
              }
              return toolTiphtml;
            }
          },
          backgroundColor: "#fff",
          borderColor: "#333",
          padding: [5, 10],
          textStyle: {
            color: "#333",
            fontSize: "16"
          }
        },
        geo: {
          layoutCenter: ['50%', '50%'],//位置
          layoutSize: '100%',//大小
          show: true,
          map: mapName,
          roam: true,
          label: {
            normal: {
              show: true,
              fontSize: 10,
              color: '#fff'
            },
            emphasis: {
              show: true,
              color: 'fff',
            }
          },
          itemStyle: {
            // 边框颜色
            normal: {
              borderColor: 'rgba(62, 127, 247, 1)',
              borderWidth: 1,
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(62, 127, 247, 0)' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(62, 127, 247, 0.2)' // 100% 处的颜色
                }],
                globalCoord: false // 缺省为 false
              },
              // shadowColor: 'rgba(128, 217, 248, 1)',
              shadowColor: 'rgba(62, 127, 247, 0.4)',
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 100
            },
            emphasis: {
              areaColor: '#389BB7',
              borderWidth: 0
            }
          }
        },
        series: [
          {
            type: 'map',
            map: mapName,
            geoIndex: 0,
            aspectScale: 0.9,//长宽比
            zoom: 1,
            showLegendSymbol: false,
            roam: true,
            itemStyle: {
              normal: {
                areaColor: '#031525',
                borderColor: '#3B5077',
              },
              emphasis: {
                areaColor: '#2B91B7',
              },
            },
            animation: false,
            data: data,
          },
          {
            name: 'Top 5',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: convertData(toolTipData),
            symbolSize: function () {
              return 15;
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke',
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true,
                color: "#fff"
              },
            },
            itemStyle: {
              normal: {
                color: '#1DE9B6',
                shadowBlur: 10,
                shadowColor: '#1DE9B6',
              },
            },
            zlevel: 1,
          },
        ],
      };
      myChart.setOption(option);

      myChart.on("click", function (params) {
        data.map(item => {
          if (item.name.includes(params.name)  ) {
            if(!item.ename){
              alert('暂无' + item.name + '地图数据');
              return;
            }
            _this.$router.push({
              path: "/province",
              query: { provinceName: item.ename, province: params.name },
            });
          }
        })
      })

    },
  },
  mounted() {
    this.initEchart();
    this.loadData()
  },
};
</script>

